<style>
	.el-tabs__header {
		background: #fff;
		padding: 0 20px;
	}
	.cat {
		box-shadow: 0 2px 12px 0 rgb(0 0 0 / 30%);
		padding: 12px 8px;
		text-align: left;
		color: #fff;
		background: url(../../assets/mask.png) no-repeat 100% 0;
		background-size: auto 60px;
	}
	.cat p {
		text-shadow: 0 2px 12px rgb(0 0 0 / 30%);
	}
	.title {
		color: #fff;
		font-size: 14px;
	}
	.gm {
		background: url(../../assets/gm.png) no-repeat top right;
		background-size: 40px auto;
	}
	.el-card__body {
		padding: 15px 25px;
	}
	.el-input__inner,
	.el-input__inner:hover,
	.el-input__inner:focus {
		color: #fff;
		background: none;
		border-radius: 0;
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom-color: #ffffffcc;
	}
	.el-input__inner::placeholder {
		color: #ffffffee;
	}
	.el-input__icon {
		color: #fff;
	}
	.title {
		color: #fff;
		font-size: 14px;
	}
	.el-empty__description p {
		text-align: center;
		color: #999;
	}
</style>
<template>
	<div style="background: linear-gradient(150deg, #2c6dd2 50%, #5eb361);min-height:100%;padding:15px">
		<h4 style="text-align:center;color:#fff">{{intro.name}}</h4>
		<h4 class="title">产品描述</h4>
		<el-card shadow="always" style="margin-bottom:10px">
			<p style="margin:0;padding:0">{{intro.desc}}</p>
		</el-card>
		<h4 class="title">认证依据</h4>
		<el-card shadow="always" style="margin-bottom:10px;">
			<p v-if="intro.file1&&intro.file1!=''" style="margin:0;"><el-link style="font-size:14px;color: #303133;" icon="el-icon-tickets" @click.native="show_pdf(intro.link1)">{{intro.file1}}</el-link></p>
			<p v-if="intro.file2&&intro.file2!=''" style="margin:0;margin-top:5px"><el-link style="font-size:14px;color: #303133;" icon="el-icon-tickets" @click.native="show_pdf(intro.link2)">{{intro.file2}}</el-link></p>
			<p v-if="intro.file3&&intro.file3!=''" style="margin:0;margin-top:5px"><el-link style="font-size:14px;color: #303133;" icon="el-icon-tickets" @click.native="show_pdf(intro.link3)">{{intro.file3}}</el-link></p>
			<p v-if="intro.file4&&intro.file4!=''" style="margin:0;margin-top:5px"><el-link style="font-size:14px;color: #303133;" icon="el-icon-tickets" @click.native="show_pdf(intro.link4)">{{intro.file4}}</el-link></p>
			<p v-if="intro.file5&&intro.file5!=''" style="margin:0;margin-top:5px"><el-link style="font-size:14px;color: #303133;" icon="el-icon-tickets" @click.native="show_pdf(intro.link5)">{{intro.file5}}</el-link></p>
		</el-card>
		<h4 class="title">产品数据库</h4>
		<el-input placeholder="请输入证书编号/产品名称/生产企业名称" style="margin-bottom:15px" v-model="keyword" clearable @input="get_detail">
			<i slot="prefix" class="el-input__icon el-icon-search"></i>
		</el-input>
		<el-card v-if="list.length==0" style="margin:0 auto">
			<div style="width:150px;margin:0 auto">
				<el-empty description="暂无匹配的产品" />
			</div>
		</el-card>

		<div v-else v-for=" (item,index) in list" :key="'p'+index" style="margin-bottom:15px">
			<!-- <p v-if="index==0" style="margin:0">{{item.jsyq}}</p> -->
			<!-- <div style="padding:0 10px;"> -->
			<el-card style="padding-bottom:60px;background:#1a3976;color:#fff;border:none;border-radius:8px 8px 40px 40px">
				<div class="gm">
					<p style="margin:0">产品名称：{{item.productName}}</p>
					<p style="margin:0">型号版本：{{item.productSize}} {{item.productBb}}</p>
					<p style="margin:0">生产企业：{{item.scCompany}}</p>
				</div>
			</el-card>
			<!-- </div> -->
			<!-- <img src="../../assets/border.png" style="margin-top:-30px;margin-top:-30px;width:100%;"> -->
			<div style="padding:0 6px;margin-top:-65px;">
				<div style="padding:15px;background:#fff;border-radius:8px;box-shadow: 0 2px 12px 0 rgba(0,0,0,.5);">
					<h4 style="margin:0 0 10px 0;font-weight:500">
						<el-tag effect="dark" size="mini" style="margin-right:5px" v-if="item.stattext=='有效'">{{item.stattext}}</el-tag>
						<el-tag effect="dark" size="mini" style="margin-right:5px" type="warning" v-else>{{item.stattext}}</el-tag>
						证书编号：{{item.no}}
					</h4>
					<p style="margin:0;color:#999;text-align:center">颁发日期<span style="margin:0 60px;color:#fff">.</span>有效期至</p>
					<p style="margin:0 0 10px 0;color:#000;font-size:16px;text-align:center">{{item.start}} <span style="margin:0 40px">→</span> {{item.end}}</p>
					<p style="margin:0;color:#000;">
						安全等级：{{item.secretLevel}}
					</p>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import request from "../../plugins/axios.js";
	export default {
		data() {
			return {
				list: [],
				intro: {},
				keyword: "",
			};
		},
		mounted() {
			document.title = "认证产品";
			this.get_detail();
		},
		methods: {
			get_detail() {
				request({
					url: "/Login/p1_list",
					data: {
						type: this.$route.params.type,
						keyword: this.keyword,
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.list = ret.data.list;
						this.intro = ret.data.intro;
						document.title = this.intro.name;
					} else {
						this.$router.push("/product");
					}
				});
			},
			show_pdf(pdf_url) {
				this.$router.push({
					path: "/show_pdf",
					query: {
						pdf: pdf_url,
					},
				});
			},
		},
	};
</script>